import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Main from './component/main/main';
function App() {
  return (
    <>
      <Main/>
    </>
  );
}

export default App;
