import React from 'react';
import logone from '../../assets/Untitled-5-01.svg';
import logotwo from "../../assets/Untitled-5-02.svg";
import logothree from "../../assets/Untitled-5-03.svg"
import logoFour from '../../assets/Untitled-5-04.svg'
import logofive from "../../assets/Untitled-5-05.svg"
import logoSix from "../../assets/Untitled-5-06.svg"
import logoSeven from '../../assets/Untitled-5-07.svg'
import logoEight from '../../assets/Untitled-5-08.svg'
import logonine from '../../assets/Untitled-5-09.svg'
import logoten from '../../assets/Untitled-5-10.svg'
import logoEleven from '../../assets/Untitled-5-11.svg'
import logoTwelve from '../../assets/Untitled-5-12.svg'
import logoThirteen from '../../assets/Untitled-5-13.svg'
import logofourteen from '../../assets/Untitled-5-14.svg'
import logoFiveteen from '../../assets/Untitled-5-15.svg'
const Header = () => {
    return (
        <div className='container-fuild'>
            <div className='col-md-12 d-flex p-2 align-items-center justify-content-between' style={{ boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                <div className='col-6'>
                    <ul className='d-flex gap-2'>
                        <li className='btn btn-outline-primary'><img src={logone} alt='one' style={{ color: 'white', width: '20px', height: '20px',cursor:'pointer' }} /></li>
                        <li className='btn btn-outline-primary'><img src={logotwo} alt='one' style={{ color: 'white', width: '20px', height: '20px' , cursor:'pointer' }} /></li>
                        <li className='btn btn-outline-primary'><img src={logothree} alt='one' style={{ color: 'white', width: '20px', height: '20px' , cursor:'pointer' }} /></li>
                        <li className='btn btn-outline-primary'><img src={logoFour} alt='one' style={{ color: 'white', width: '20px', height: '20px' , cursor:'pointer' }} /></li>
                        <li className='btn btn-outline-primary'><img src={logofive} alt='one' style={{ color: 'white', width: '20px', height: '20px' , cursor:'pointer' }} /></li>
                        <li className='btn btn-outline-primary'><img src={logoSix} alt='one' style={{ color: 'white', width: '20px', height: '20px' , cursor:'pointer' }} /></li>
                        <li className='btn btn-outline-primary'><img src={logoSeven} alt='one' style={{ color: 'white', width: '20px', height: '20px' , cursor:'pointer' }} /></li>
                        <li className='btn btn-outline-primary'><img src={logoEight} alt='one' style={{ color: 'white', width: '20px', height: '20px' , cursor:'pointer' }} /></li>
                        <li className='btn btn-outline-primary'><img src={logonine} alt='one' style={{ color: 'white', width: '20px', height: '20px' , cursor:'pointer' }} /></li>
                        <li className='btn btn-outline-primary'><img src={logoten} alt='one' style={{ color: 'white', width: '20px', height: '20px' , cursor:'pointer' }} /></li>
                        <li className='btn btn-outline-primary'><img src={logoEleven} alt='one' style={{ color: 'white', width: '20px', height: '20px'  , cursor:'pointer'}} /></li>
                        <li className='btn btn-outline-primary'><img src={logoTwelve} alt='one' style={{ color: 'white', width: '20px', height: '20px' , cursor:'pointer' }} /></li>
                        <li className='btn btn-outline-primary'><img src={logoThirteen} alt='one' style={{ color: 'white', width: '20px', height: '20px' , cursor:'pointer' }} /></li>
                        <li className='btn btn-outline-primary'><img src={logofourteen} alt='one' style={{ color: 'white', width: '20px', height: '20px' , cursor:'pointer' }} /></li>
                        <li className='btn btn-outline-primary'><img src={logoFiveteen} alt='one' style={{ color: 'white', width: '20px', height: '20px' , cursor:'pointer' }} /></li>
                    </ul>
                </div>
                <div className=' col-md-3' >
                    <h4 className='text-right fs-3'>ICAP.ICP New Record</h4>
                </div>
            </div>
        </div>
    )
}

export default Header