import React from 'react'

const PageFive = () => {
  return (
    <div className='container-fuild'>
        <div className='row field mt-4'>
            <span className='spanfive'>E. Problem Behavior</span>
            <div className='col mt-3'>
                <div className='row' style={{marginLeft:'30px'}}>
                    <div className="col-md-3">
                        <h5>1. Hurtful to self</h5>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Frequncy</label>
                        </div>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Severity</label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <h5>3. Destructive</h5>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Frequncy</label>
                        </div>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Severity</label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <h5>5. Unusual Habits</h5>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Frequncy</label>
                        </div>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Severity</label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <h5>7. Withdrawn/inattentive</h5>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Frequncy</label>
                        </div>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Severity</label>
                        </div>
                    </div>
                </div>
                <div className='row' style={{marginLeft:'30px'}}>
                <div className="col-md-3">
                        <h5>1. Hurtful to Others</h5>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Frequncy</label>
                        </div>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Severity</label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <h5>4. Dis ruptive</h5>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Frequncy</label>
                        </div>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Severity</label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <h5>6. Socially Offernsive</h5>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Frequncy</label>
                        </div>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Severity</label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <h5>8. Uncooperative</h5>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Frequncy</label>
                        </div>
                        <div className='d-flex gap-2 p-2 mx-3'>
                            <input type="text" style={{width:'30px',height:'30px'}} />
                            <label>Severity</label>
                        </div>
                    </div>
                </div>
                <hr style={{border:'2px solid'}} />
            </div>
            
            <div className="col-md-12">
                <div className="row">
                    <div className='col-auto'>
                        <label>9. Response to Problem Behavior</label>
                        <input type="text" style={{width:'30px',height:'20px',marginLeft:"5px",marginRight:'5px'}} /> (0-10)
                    </div>
                </div> 
            </div>
            <div className="col-md-12">
                <div className="row p-3">
                    <div className='col-auto'>
                        <label> Specific example of  Problem Behavior</label>
                        <input type="text" style={{width:'200px',height:'20px',marginLeft:"5px",marginRight:'5px'}} /> (0-10)
                    </div>
                </div> 
            </div>
        </div>
        <div className='mt-3 col-12 d-flex justify-content-end'>
                <button className='btn btn-outline-success btn-lg '>
                    Next
                </button>
            </div>
    </div>
  )
}

export default PageFive