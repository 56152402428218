import React from 'react'

const PageSix = () => {
    return (
        <div className='container-fuild mt-5'>
            <div className="container">
                <div className='row d-flex'>
                    <div className="col-md-3 mx-auto">
                        <div className='row'>
                            <div className="col-12 field">
                                <p className='spanThree'>F. Residential Placement</p>
                                <div className='row py-4 px-2'>
                                    <div className="col">
                                        <h5>1. Current</h5>
                                        <div className='d-flex gap-2 p-2 mx-3'>
                                            <input type="number" style={{ width: '30px', height: '30px' }} />
                                            <label>(1-12)</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <h5>2. Recommended</h5>
                                        <div className='d-flex gap-2 p-2 mx-3'>
                                            <input type="number" style={{ width: '30px', height: '30px' }} />
                                            <label>(1-13)</label>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <label>Other</label>
                                        <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col-12 field">
                                <p className='spanThree'>G. Daytime Program</p>
                                <div className='row py-3 px-2'>
                                    <div className="col">
                                        <h5>1. Current</h5>
                                        <div className='d-flex gap-2 p-2 mx-3'>
                                            <input type="number" style={{ width: '30px', height: '30px' }} />
                                            <label>(1-10)</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <h5>2. Recommended</h5>
                                        <div className='d-flex gap-2 p-2 mx-3'>
                                            <input type="number" style={{ width: '30px', height: '30px' }} />
                                            <label>(1-11)</label>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <label>Other</label>
                                        <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-4 field">
                        <p className='spanThree'>H. Support Services</p>
                        <div className="row">
                            <div className="col-12">
                                <table className='mx-auto mt-5'>
                                    <tr className='gap-2 d-flex'>
                                        <td className='d-flex justify-content-center' style={{ width: '30px', height: '30px' }} >1</td>
                                        <td className='d-flex justify-content-center' style={{ width: '30px', height: '30px' }} >2</td>
                                        <td style={{width:'55px'}}></td>
                                        <td className='d-flex justify-content-center' style={{ width: '30px', height: '30px' }}>1</td>
                                        <td className='d-flex justify-content-center' style={{ width: '30px', height: '30px' }} >2</td>
                                        <td></td>

                                    </tr>
                                    <tr className='gap-2 d-flex'>
                                        <td><input type="tell" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td >1. none</td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>8</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-3'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td style={{ width: '55px' }}>2.</td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>9.</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-3'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td style={{ width: '55px' }}>3.</td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>10.</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-3'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td style={{ width: '55px' }}>4.</td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>11.</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-2'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td style={{ width: '55px' }}>5.</td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>12.</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-3'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td style={{ width: '55px' }}>6.</td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>13.</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-3'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td style={{ width: '55px' }}>7.</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                                <div className='row mt-5 d-flex justify-content-center'>
                                    <div className='col-6 gap-3 d-flex flex-column'>
                                        <label>Column 1: Used</label>
                                        <label>Column 2: Needed</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mx-auto field">
                        <p className='spanThree'>L. Socaial & Leisure Activities</p>
                        <div className="row">
                            <div className="col-12">
                                <table className='mx-auto mt-4'>
                                    <tr className='gap-2 d-flex'>
                                        <td className='d-flex justify-content-center' style={{ width: '30px', height: '30px' }}>1</td>
                                        <td className='d-flex justify-content-center' style={{ width: '30px', height: '30px' }}>2</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-2'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>1. none</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-3'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>2.</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-3'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>3.</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-3'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>4.</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-3'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>5.</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-3'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>6.</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-3'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>7.</td>
                                    </tr>
                                    <tr className='gap-2 d-flex mt-3'>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td><input type="number" style={{ width: '30px', height: '30px' }} /></td>
                                        <td>8.</td>
                                    </tr>
                                </table>
                                <div className='row mt-3 d-flex justify-content-center'>
                                    <div className='col-9 gap-2 d-flex flex-column' >
                                        <label>Column 1: Within Last Month</label>
                                        <label>Column 2: Limited Factors</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-3 col-11 d-flex justify-content-end'>
                        <button className='btn btn-outline-success btn-lg '>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageSix