import React from 'react'
import './pageone.css'
const Pageone = () => {
    return (
        <div className='container-fluid' >
            <div className='row'>
                <div className='col-12'><h3>Personal Information Section</h3></div>
            </div>

            <div className='row '>
                <div className="col-md-8">
                    <div className='row '>
                        <div className='col-md-3'>
                            <label for="inputPassword6" class="col-form-label">First Name</label>
                            <input type="text" placeholder='First Name' className='form-control' aria-describedby="passwordHelpInline" />
                        </div>
                        <div className='col-md-3'>
                            <label for="inputPassword6" class="col-form-label">Last Name</label>
                            <input type="text" placeholder='Last Name' id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                        </div>
                        <div className='col-md-3'>
                            <label for="inputPassword6" class="col-form-label">Street</label>
                            <input type="text" placeholder='123 example Street' id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                        </div>
                        <div className='col-md-3'>
                            <label for="inputPassword6" class="col-form-label">City</label>
                            <input type="text" placeholder='New York ' id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-md-3'>
                            <label for="validationCustom05" class="form-label">Zip</label>
                            <input type="text" placeholder='10009' class="form-control" id="validationCustom05" />
                        </div>
                        <div className='col-md-3'>
                            <label for="validationCustom04" class="form-label">State</label>
                            <select class="form-select" id="validationCustom04" >
                                <option selected disabled value="">Choose...</option>
                                <option>...</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <label for="inputPassword6" class="col-form-label">Phone</label>
                            <input type="text" placeholder='+917.....' id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                        </div>
                        <div className='col-md-2'>
                            <label for="inputPassword6" class="col-form-label">MI</label>
                            <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col'>
                            <div className='col-auto'>
                                <label for="inputPassword6" class="col-form-label">Residential Facility</label>
                                <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                            </div>
                            <div className='col-auto'>
                                <label for="inputPassword6" class="col-form-label">School/day Prog</label>
                                <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                            </div>
                            <div className='col-auto'>
                                <label for="inputPassword6" class="col-form-label">Country/District</label>
                                <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                            </div>
                        </div>

                    </div>
                </div>
                <div className='col-md-4 field mt-sm-4' >
                    <p className='span'>ID Codes</p>
                    <div className='row py-3'>
                        <div className='col-12'>
                            <label for="inputPassword6" class="col-form-label">client</label>
                            <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                        </div>
                        <div className='col-12'>
                            <label for="inputPassword6" class="col-form-label">Residence</label>
                            <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                        </div>
                        <div className='col-12'>
                            <label for="inputPassword6" class="col-form-label">Day Prog</label>
                            <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                        </div>

                        <div className='col-12'>
                            <label for="inputPassword6" class="col-form-label">Co/Dist</label>
                            <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                        </div>
                        <div className='col-12'>
                            <label for="inputPassword6" class="col-form-label">case Mgr</label>
                            <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                        </div>
                        <div className='col-12'>
                            <label for="inputPassword6" class="col-form-label">Other..</label>
                            <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-md-8 mt-4">
                            <div className='col-auto'>
                                <label for="inputPassword6" class="col-form-label">Case manager</label>
                                <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                            </div>
                            <div className='col-auto'>
                                <label for="inputPassword6" class="col-form-label">Parent/Guardian</label>
                                <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                            </div>
                            <div className='col-auto'>
                                <label for="inputPassword6" class="col-form-label">Respondent</label>
                                <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                            </div>
                            <div className='col-auto'>
                                <label for="inputPassword6" class="col-form-label">Relation</label>
                                <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                            </div>
                            <div className='col-auto'>
                                <label for="inputPassword6" class="col-form-label">Relation For Eval</label>
                                <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className='col-auto'>
                                <h5 className='text-center'>Phone Number</h5>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <label for="inputPassword6" class="col-form-label">Case manager Phone</label>
                                    <input type="number" id="inputPassword6" placeholder='+917...' class="form-control" aria-describedby="passwordHelpInline" />
                                </div>   <div className='col-12'>
                                    <label for="inputPassword6" class="col-form-label">Parent/Guardian Phone</label>
                                    <input type="number" id="inputPassword6" placeholder='+917.....' class="form-control" aria-describedby="passwordHelpInline" />
                                </div>
                                <div className='col-12'>
                                    <label for="inputPassword6" class="col-form-label">Respondent Phone</label>
                                    <input type="number" id="inputPassword6" placeholder='+917....' class="form-control" aria-describedby="passwordHelpInline" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4 field mt-sm-4' style={{height:'180px'}}>
                    <div className='col-auto'>
                        <h5 className='span' >Date</h5>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <label for="inputPassword6" class="col-form-label">Eval Dtae</label>
                            <input type="date" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                        </div>   <div className='col-md-12'>
                            <label for="inputPassword6" class="col-form-label">Birth date</label>
                            <input type="date" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-3 col-12 d-flex justify-content-end'>
                <button className='btn btn-outline-success btn-lg '>
                    Next
                </button>
            </div>
        </div>
    )
}

export default Pageone