import React from 'react'

const PageTwo = () => {
  return (
    <>
      <div className='container-fuild '>
        <div >
          <div className="row mt-5  gap-3">
            <div className='col-md-3 '>
              <div className='col-12 field'>
                <p className='spanTwo ' style={{ fontSize: "14px",left:'15px' }}>A Descriptive Information</p>
                <div className="row p-3 ">
                  <div className="col-12 gap-2 d-flex justify-content-center align-items-center" style={{ height: '40px' }}>
                    <input type="text" className='col-6' style={{ width: '30px', height: '30px' }} />
                    <label className='col-6'>Sex</label>
                  </div>
                  <div className='col-12 gap-2 d-flex justify-content-center align-items-center' style={{ height: '40px' }}>
                    <input type="text" className='col-6' style={{ width: '30px', height: '30px' }} />
                    <label className='col-6'>Height</label>
                  </div>
                  <div className='col-12 gap-2 d-flex justify-content-center align-items-center' style={{ height: '40px' }}>
                    <input className='col-6' type="text" style={{ width: '30px', height: '30px' }} />
                    <label className='col-6'>weight</label>
                  </div>
                  <div className='col-12 gap-2 d-flex justify-content-center align-items-center' style={{ height: '40px' }}>
                    <input className='col-6' type="text" style={{ width: '30px', height: '30px' }} />
                    <label className='col-6'>Race</label>
                  </div>
                  <div className='col-12 gap-2 d-flex justify-content-center align-items-center' style={{ height: '40px' }}>
                    <input className='col-6' type="text" style={{ width: '30px', height: '30px' }} />
                    <label className='col-6'>Hispanic</label>
                  </div>
                  <div className='col-12 gap-2 d-flex justify-content-center align-items-center' style={{ height: '40px' }}>
                    <input className='col-6' type="text" style={{ width: '30px', height: '30px' }} />
                    <label className='col-6'>Language understand</label>
                  </div>
                  <div className='col-12 gap-2 d-flex justify-content-center align-items-center' style={{ height: '40px' }}>
                    <input className='col-6' type="text" style={{ width: '30px', height: '30px' }} />
                    <label className='col-6'>Expression</label>
                  </div>
                  <div className='col-12 gap-2 d-flex justify-content-center align-items-center' style={{ height: '40px' }}>
                    <input className='col-6' type="text" style={{ width: '30px', height: '30px' }} />
                    <label className='col-6'>Marital</label>
                  </div>
                  <div className='col-12 gap-2 d-flex justify-content-center align-items-center' style={{ height: '40px' }}>
                    <input className='col-6' type="text" style={{ width: '30px', height: '30px' }} />
                    <label className='col-6'>Legal</label>
                  </div>
                </div>
              </div>
              <div className="row my-2 p-2" style={{fontSize:'18px'}}>
                <div className="col-md-12">
                  Enter Number next to Circle Marked
                </div>
                <div className="col-md-12">
                  Enter 0 if blank/missing
                </div>
                <div className="col-md-12">
                  All That apply: 1 = yes ; 0 = no
                </div>
              </div>

            </div>
            <div className="col-md-4 field">
              <p className='spanTwo ' style={{ fontSize: "15px" }}>B diagnostic status</p>
              <div className="row mt-3">
                <div className="col-md-10 mx-auto gap-3 d-flex align-items-center" style={{ height: '40px' }}>
                  <label>
                    Primary (1-13)
                  </label>
                  <input type="text" className='col-6' style={{ width: '30px', height: '30px' }} />
                </div>
                <div className="col-md-10 mx-auto">
                  <p>Addtional (Mark All That Apply)</p>
                </div>
              </div>
              <div className="col-6 mx-auto">
                <table className='col-8'>
                  <tr>
                    <td className='d-flex gap-2'>
                      <input type="text" className='col-6' style={{ width: '30px', height: '30px' }} /><label className='col-6'>1 None</label>
                    </td>
                    <td></td>
                  </tr>
                  <tr className='d-flex gap-5' >
                    <td className='d-flex gap-2' >
                      <input type="text" style={{ width: '30px', height: '30px' }} /><label >2</label>
                    </td>
                    <td className='d-flex gap-2' >
                      <input type="text" style={{ width: '30px', height: '30px' }} /><label>8</label>
                    </td>
                  </tr>
                  <tr className='d-flex gap-5' >
                    <td className='d-flex gap-2' >
                      <input type="text" style={{ width: '30px', height: '30px' }} /><label >3</label>
                    </td>
                    <td className='d-flex gap-2' >
                      <input type="text" style={{ width: '30px', height: '30px' }} /><label>9</label>
                    </td>
                  </tr>
                  <tr className='d-flex gap-5' >
                    <td className='d-flex gap-2' >
                      <input type="text" style={{ width: '30px', height: '30px' }} /><label >4</label>
                    </td>
                    <td className='d-flex gap-2' >
                      <input type="text" style={{ width: '30px', height: '30px' }} /><label>10</label>
                    </td>
                  </tr>
                  <tr className='d-flex gap-5' >
                    <td className='d-flex gap-2' >
                      <input type="text" style={{ width: '30px', height: '30px' }} /><label >5</label>
                    </td>
                    <td className='d-flex gap-2' >
                      <input type="text" style={{ width: '30px', height: '30px' }} /><label>11</label>
                    </td>
                  </tr>
                  <tr className='d-flex gap-5' >
                    <td className='d-flex gap-2' >
                      <input type="text" style={{ width: '30px', height: '30px' }} /><label >6</label>
                    </td>
                    <td className='d-flex gap-2' >
                      <input type="text" style={{ width: '30px', height: '30px' }} /><label>12</label>
                    </td>
                  </tr>
                  <tr className='d-flex gap-5' >
                    <td className='d-flex gap-2' >
                      <input type="text" style={{ width: '30px', height: '30px' }} /><label >7</label>
                    </td>
                    <td className='d-flex gap-2' >
                      <input type="text" style={{ width: '30px', height: '30px' }} /><label>13</label>
                    </td>
                  </tr>
                </table>
              </div>
              <div className='row px-2'>
                <div className='col-12'>
                  <label for="inputPassword6" class="col-form-label">Health Problem</label>
                  <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                </div>
              </div>
              <div className='row p-2'>
                <div className='col-12'>
                  <label for="inputPassword6" class="col-form-label">Other Diagonsis</label>
                  <input type="text" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline" />
                </div>
              </div>
            </div>

            <div className="col-md-4 field">
              <p className='spanTwo'>C. Funtional Limitations</p>
              <div className="row pt-4">
                <div className="col-12 px-3" style={{ height: '40px' }}>
                  <input type="text" className='col-2' style={{ width: '30px', height: '30px' }} />
                  <label className=' mx-4 col-auto'>1. Level of mental Retardation</label>
                </div>
                <div className="col-12 px-3" style={{ height: '40px' }}>
                  <input type="text" className='col-2' style={{ width: '30px', height: '30px' }} />
                  <label className=' mx-4 col-auto'>2. vision</label>
                </div>
                <div className="col-12 px-3" style={{ height: '40px' }}>
                  <input type="text" className='col-2' style={{ width: '30px', height: '30px' }} />
                  <label className=' mx-4 col-auto'>3. Hearing</label>
                </div>
                <div className="col-12 px-3" style={{ height: '40px' }}>
                  <input type="text" className='col-2' style={{ width: '30px', height: '30px' }} />
                  <label className=' mx-4 col-auto'>4. frequency of Seizures</label>
                </div>
                <div className="col-12 px-3" style={{ height: '40px' }}>
                  <input type="text" className='col-2' style={{ width: '30px', height: '30px' }} />
                  <label className=' mx-4 col-auto'>5. Health</label>
                </div>
                <div className="col-12 px-3" style={{ height: '40px' }}>
                  <input type="text" className='col-2' style={{ width: '30px', height: '30px' }} />
                  <label className=' mx-4 col-auto'>6. Required Care</label>
                </div>
                <div className="col-12">
                  <p className='col-10 mx-auto'>7. Current Medication</p>
                  <table>
                    <tr className='gap-3 d-flex'>
                      <td>
                        <label>1.</label>
                        <input type="number" style={{ width: '30px', height: '30px', marginLeft: '2px' }} />
                      </td>
                      <td>
                        <label>2.</label>
                        <input type="number" style={{ width: '30px', height: '30px', marginLeft: '2px' }} />
                      </td>
                      <td>
                        <label>3.</label>
                        <input type="number" style={{ width: '30px', height: '30px', marginLeft: '2px' }} />
                      </td>
                      <td>
                        <label>4.</label>
                        <input type="number" style={{ width: '30px', height: '30px', marginLeft: '2px' }} />
                      </td>
                      <td>
                        <label>5.</label>
                        <input type="number" style={{ width: '30px', height: '30px', marginLeft: '2px' }} />
                      </td>
                      <td>
                        <label>6.</label>
                        <input type="number" style={{ width: '30px', height: '30px', marginLeft: '2px' }} />
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="col-12 px-3 my-2" style={{ height: '40px' }}>
                  <input type="text" className='col-2' style={{ width: '30px', height: '30px' }} />
                  <label className=' mx-4 col-auto'>8. Arm/Hand</label>
                </div>
                <div className="col-12 px-3" style={{ height: '40px' }}>
                  <input type="text" className='col-2' style={{ width: '30px', height: '30px' }} />
                  <label className=' mx-4 col-auto'>9. Mobility</label>
                </div>
                <div className="col-12 mb-2">
                  <p className='col-10 mx-auto'>10. Mobility Assitance Needed</p>
                  <table>
                    <tr className='gap-3 d-flex'>
                      <td>
                        <label>1.</label>
                        <input type="number" style={{ width: '30px', height: '30px', marginLeft: '2px' }} />
                      </td>
                      <td>
                        <label>2.</label>
                        <input type="number" style={{ width: '30px', height: '30px', marginLeft: '2px' }} />
                      </td>
                      <td>
                        <label>3.</label>
                        <input type="number" style={{ width: '30px', height: '30px', marginLeft: '2px' }} />
                      </td>
                      <td>
                        <label>4.</label>
                        <input type="number" style={{ width: '30px', height: '30px', marginLeft: '2px' }} />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className='mt-3 col-11 d-flex justify-content-end'>
                <button className='btn btn-outline-success btn-lg '>
                    Next
                </button>
            </div>
          </div>
        </div>
      </div>


      {/* 
<div className="col-lg-3 field">
<p className='span fs-5'>A Descriptive Information</p>  
 <div className='row mt-5 p-4'>
     <div className="col-12  gap-3 d-flex justify-content-center align-items-center">
         <input type='number' style={{width:'40px'}}/>
         <span style={{fontSize:'20px'}}> 1. sex</span>
     </div>
     <div className="col-12 mt-2 gap-3 d-flex">
         <input type='number' style={{width:'40px'}}/>
         <input type='number' style={{width:'40px'}}/>
         <span style={{fontSize:'20px'}}> 2. Height</span>
     </div>
     <div className="col-12 mt-2 gap-3 d-flex justify-content-center align-items-center">
         <input type='number' style={{width:'40px'}}/>
         <span style={{fontSize:'20px'}}> 1. weight</span>
     </div>
 </div>
</div> */}
    </>)
}

export default PageTwo