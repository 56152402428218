import React from 'react'
import { useState } from 'react';
import Pageone from '../../pages/pageOne/Pageone';
import PageTwo from '../../pages/pageTwo/pageTwo';
import PageThree from '../../pages/pageThree/PageThree'
import PageFour from '../../pages/pageFour/PageFour';
import PageFive from '../../pages/pageFive/PageFive';
import PageSix from '../../pages/pageSix/PageSix';
import Header from '../../pages/header/Header';
const Main = () => {
    const [FirstPage, setpageOne] = useState(true);
    const [SecondPage, setpageTwo] = useState(false);
    // const [Active, setActive] = useState(false);
    const [ThiredPage, setThiredPage] = useState(false);
    const [FourPage, setFourPage] = useState(false);
    const [FivePage, setFivePage] = useState(false);
    const [sixPage, setSixPage] = useState(false);
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 ">
                        <section>
                            <div className="col-md-12 pt-2 pb-5">
                                <div className="row">
                                    <Header/>
                                    <div className="col-md-10 p-4 mx-auto d-flex justify-content-center flex-wrap mt-md-4 " style={{ boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                                        <div className="ms-md-2">
                                            <button
                                                className={`btn btn-outline-primary ${(FirstPage) ? 'active' : ''}`}
                                                onClick={() => {
                                                    setpageOne(true);
                                                    setpageTwo(false);
                                                    setThiredPage(false)
                                                    setFourPage(false)
                                                    setFivePage(false)
                                                    setSixPage(false)
                                                }}
                                            >
                                                Page 1
                                            </button>
                                        </div>
                                        <div className="ms-md-2">
                                            <button

                                                onClick={() => {
                                                    setpageOne(false);
                                                    setpageTwo(true);
                                                    setThiredPage(false);
                                                    setFourPage(false);
                                                    setFivePage(false)
                                                    setSixPage(false)
                                                }}
                                                className={`btn btn-outline-primary ${(SecondPage) ? 'active' : ''}`}
                                            >
                                                Page 2-3
                                            </button>
                                        </div>
                                        <div className="ms-md-2">
                                            <button
                                                className={`btn btn-outline-primary ${(ThiredPage) ? 'active' : ''}`}
                                                onClick={() => {
                                                    setpageOne(false);
                                                    setpageTwo(false);
                                                    setThiredPage(true);
                                                    setFourPage(false);
                                                    setFivePage(false)
                                                    setSixPage(false)
                                                }}
                                            >
                                                Page 4-5
                                            </button>
                                        </div>
                                        <div className="ms-md-2">
                                            <button
                                                className={`btn btn-outline-primary ${(FourPage) ? 'active' : ''}`}
                                                onClick={() => {
                                                    setpageOne(false);
                                                    setpageTwo(false);
                                                    setThiredPage(false);
                                                    setFourPage(true);
                                                    setFivePage(false)
                                                    setSixPage(false)
                                                }}
                                            >
                                                Page 6-7
                                            </button>
                                        </div>
                                        <div className="ms-md-2">
                                            <button
                                                className={`btn btn-outline-primary ${(FivePage) ? 'active' : ''}`}
                                                onClick={() => {
                                                    setpageOne(false);
                                                    setpageTwo(false);
                                                    setThiredPage(false);
                                                    setFourPage(false);
                                                    setFivePage(true)
                                                    setSixPage(false)
                                                }}
                                            >
                                                Page 8-9
                                            </button>
                                        </div>
                                        <div className="ms-md-2">
                                            <button
                                                className={`btn btn-outline-primary ${(sixPage) ? 'active' : ''}`}
                                                onClick={() => {
                                                    setpageOne(false);
                                                    setpageTwo(false);
                                                    setThiredPage(false);
                                                    setFourPage(false);
                                                    setFivePage(false)
                                                    setSixPage(true)
                                                }}
                                            >
                                                Page 10-11
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-11 mx-auto mt-3">
                                    {FirstPage ? <Pageone /> : null}
                                    {SecondPage ? <PageTwo /> : null}
                                    {ThiredPage ? <PageThree /> : null}
                                    {FourPage ? <PageFour /> : null}
                                    {FivePage ? <PageFive /> : null}
                                    {sixPage ? <PageSix /> : null}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main