import React from 'react'

const PageThree = () => {
  return (
    <div className='container-fuild'>
      <div className='row pt-3'>
        <div className="col-8 mx-auto px-5 py-4 field">
          <p className='spanThree'>D. Adaptive Behavior</p>
          <div className="">
            <div className="row pt-4">
              <div className='col-md-6 px-2'>
              <div className="col-12 field">
              <p className='spanThree'style={{left:'15px'}} >1. Motor Skills</p>
                <div className='row pt-2'>
                  <div className="col-md-7 mx-auto p-2">
                    <table>
                      <tr className='d-flex gap-4'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>1.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>8.&nbsp; </label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' ,marginLeft:'2px' }} />
                          <label>15.</label>
                        </td>
                      </tr>
                      <tr className='d-flex gap-4 py-2'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>2.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>9.&nbsp; </label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px',marginLeft:'3px' }} />
                          <label>16.</label>
                        </td>
                      </tr>
                      <tr className='d-flex gap-4'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>3.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>10.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>17.</label>
                        </td>
                      </tr>
                      <tr className='d-flex gap-4 py-2'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>4.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>11.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>18.</label>
                        </td>
                      </tr>
                      <tr className='d-flex gap-4'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>5.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>12.</label>
                        </td>
                        <td>

                        </td>
                      </tr>
                      <tr className='d-flex gap-4 py-2'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>6.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>13.</label>
                        </td>
                        <td>

                        </td>
                      </tr>
                      <tr className='d-flex gap-4'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>7.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>14.</label>
                        </td>
                        <td>

                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              </div>
              <div className="col-md-6 field">
                <p className='spanThree'>2. Social and Communication Skills</p>
                <div className='row pt-2'>
                  <div className="col-md-8 mx-auto p-2">
                    <table>
                      <tr className='d-flex gap-4'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>1.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>8. &nbsp; </label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>15.</label>
                        </td>
                      </tr>
                      <tr className='d-flex gap-4 py-2'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>2.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>9. &nbsp; </label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>16.</label>
                        </td>
                      </tr>
                      <tr className='d-flex gap-4'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>3.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>10.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>17.</label>
                        </td>
                      </tr>
                      <tr className='d-flex gap-4 py-2'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>4.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>11.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>18.</label>
                        </td>
                      </tr>
                      <tr className='d-flex gap-4'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>5.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>12.</label>
                        </td>
                        <td>

                        </td>
                      </tr>
                      <tr className='d-flex gap-4 py-2'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>6.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>13.</label>
                        </td>
                        <td>

                        </td>
                      </tr>
                      <tr className='d-flex gap-4'>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>7.</label>
                        </td>
                        <td className='d-flex gap-2 align-items-center'>
                          <input type="text" name="" id="" style={{ width: "40px", height: '40px' }} />
                          <label>14.</label>
                        </td>
                        <td>

                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <div className="col-8 mx-auto field  mt-3">
             <div className='row d-flex justify-content-center align-items-center  py-2'>
              <div className="col-md-3 mx-5">
                 To verify these Items, Count the number of boxes checked in each colums of the Response Booklet,and enter here
              </div>
              <div className="col-md-6">
                <table className=''>
                  <tr>
                    <td></td>
                    <td className='px-4'>0s</td>
                    <td className='px-4'>1s</td>
                    <td className='px-4'>2s</td>
                    <td className='px-4'>3s</td>
                  </tr>
                  <tr >
                    <td>Motor</td>
                    <td ><input className='mx-3' type="text" style={{width:'30px',height:'30px'}} /></td>
                    <td><input type="text" className='mx-3' style={{width:'30px',height:'30px'}} /></td>
                    <td><input type="text"  className='mx-3'style={{width:'30px',height:'30px'}} /></td>
                    <td><input type="text" className='mx-3' style={{width:'30px',height:'30px'}} /></td>
                  </tr>
                  <tr>
                    <td>Social</td>
                    <td ><input className='mx-3' type="text" style={{width:'30px',height:'30px'}} /></td>
                    <td><input type="text" className='mx-3' style={{width:'30px',height:'30px'}} /></td>
                    <td><input type="text"  className='mx-3'style={{width:'30px',height:'30px'}} /></td>
                    <td><input type="text" className='mx-3' style={{width:'30px',height:'30px'}} /></td>
                  </tr>
                </table>
              </div>
             </div>
        </div>
        <div className='mt-3 col-10 d-flex justify-content-end'>
                <button className='btn btn-outline-success btn-lg '>
                    Next
                </button>
            </div>
    </div>
  )
}

export default PageThree